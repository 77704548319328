/*noinspection CssInvalidAtRule*/
@import-normalize; /* bring in normalize.css styles */

* , *:before, *:after{
  box-sizing:border-box;
  -moz-box-sizing:border-box;
  -webkit-box-sizing:border-box;
  -ms-box-sizing:border-box;
}

html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  padding: 0;
  font-family: 'Ubuntu', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #000000;
}

h1, h2, h3, h4, h5 {
  margin-top: 1em;
  font-family: 'Ubuntu', sans-serif;
  color: #dde1e6;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.page__container {
  min-height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.pl1 {
  padding-left: 1em;
}

.pl2 {
  padding-left: 2em;
}

.pr1 {
  padding-right: 1em;
}

.pr2 {
  padding-right: 2em;
}