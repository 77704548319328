.carousel__container {
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: center;
    align-items: center;
    position: relative;
}

.carousel__image-container {
    display: flex;
    flex-direction: row;
}

.carousel__image {
    display: block;
    max-width: 100%;
    max-height: 20em;
    width: auto;
    height: auto;
}

.carousel__image-indicator {
    cursor: pointer;
    display: block;
    width: 10px;
    height: 10px;
    background-color: rgba(255, 255, 255, 0.35);
    border-radius: 50%;
    margin: 1em;
    -webkit-transition: all 0.15s ease-in;
    -moz-transition: all 0.15s ease-in;
    -ms-transition: all 0.15s ease-in;
    -o-transition: all 0.15s ease-in;
    transition: all 0.15s ease-in;
}

.carousel__image-indicator.active {
    background-color: #ffffff;
}

.carousel__indicator-container {
    display: flex;
    flex-direction: row;
    background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.05), rgba(0, 0, 0, 0.75));
    position: absolute;
    bottom: 0px;
    max-width: 100%;
    max-height: 20em;
    width: auto;
    height: auto;
    justify-content: center;
    border-top-left-radius: 50%;
    border-top-right-radius: 50%;
}