
.work__work-item {
    display: flex;
    align-items: center;
    justify-content: center;

    border-radius: 5%;

    margin: 0.4em;

    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    background-color: #ffffff;

    border: 4px solid #000000;
}

.work__work-item-text {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
}

.work__work-item button {
    background-color: rgba(0, 0, 0, 0.6);
    border: none;
    color: white;
    text-decoration: none;
    font-size: 1em;

    width: 5em;
    height: 5em;

    transition-property: all;
    transition-delay: 0s;
    transition-duration: 0.5s;

    cursor: pointer;
}

.work__work-item button:hover {
    background-color: rgba(0, 0, 0, 0.0);
    color: rgba(0, 0, 0, 0.0);
}

.work__work-item.active {
    border: 4px solid #fff;
}

.work__ati {
    background-image: url(./ati.jpg);
}

.work__mars {
    background-image: url(./mars.png);
}

.work__caesars {
    background-image: url(./caesars.png);
}

.work__vsp {
    background-image: url(./vsp.jpg);
}