.work__container {
    display: flex;
    justify-content: center;
    flex-direction: row;
    flex-wrap: wrap;
}

.work__background {
    background-color: #061727;
    /*background-image: linear-gradient(to bottom left, #004144, #003a6d);*/
}

.work__work-item-content-container {
    max-width: 1080px;
    min-height: 70vh;
    text-align: justify;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    background-color: #343a3f;
    font-size: 0.75em;
    padding-left: 1em;
    padding-right: 1em;
    border-radius: 15px;
    margin-bottom: 2em;
}