.core__button {
    background: transparent;
    border-radius: 5px;
    border: 2px solid #ffffff;
    color: #ffffff;
    margin: 0em 1em;
    padding: 0.25em 0.75em;
    font-size: 0.85em;
    text-decoration: none;
    transition: background-color 0.5s;
    font-family: 'Kanit', sans-serif;
}

.core__button:hover {
    background-color: #000000;
}

.core__button-icon {
    margin-right: 0.25em;
}
