.welcome__background {
  min-height: 100vh;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-image: url(background.jpg);
  background-size: cover;
  filter: blur(10px);
}

.welcome__profile-image {
  border-radius: 50%;
  border: 2px solid #ffffff;
  z-index: 1;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.6), 0 3px 10px 0 rgba(0, 0, 0, 0.59);
  width: 8em;
}

.welcome__card {
  position: relative;
  background-color: rgba(0, 0, 0, 0.7);
  color: #ffffff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0.85em;
  border-radius: 25px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.6), 0 6px 20px 0 rgba(0, 0, 0, 0.59);
  margin-bottom: 2em;
  margin-top: 2em;
}

.welcome__card-greeting {
  font-size: 3.2em;
  font-family: 'Ubuntu', sans-serif;
}

.welcome__card-greeting-small {
  font-size: 2em;
  font-family: 'Open Sans Condensed', sans-serif;
  color: #c1c7cd;
}
