.nav-bar__container {
  background-color: #131212;
  min-height: 1em;
  width: 100%;
  position: sticky;
  top: 0;
  padding: 0.2em;
  z-index: 10;
  font-family: 'Open Sans Condensed', sans-serif;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.6), 0 3px 10px 0 rgba(0, 0, 0, 0.59);
}

.nav-bar__container a {
  text-decoration: none;
  font-size: 0.85em;
  margin: 1rem;
}

.nav-bar__container a.active, .nav-bar__container a.active:link, .nav-bar__container a.active:visited {
  border-bottom:  2px solid #ffffff;
  color: #ffffff;
}

.nav-bar__container a:link, .nav-bar__container a:visited {
  color: #a2a9b0;
}

.nav-bar__container a:hover, .nav-bar__container a:active {
  color: #ffffff;
}

.nav-bar__logo {
  font-size: 0.7em;
  text-shadow: 1px 1px #b8b8b8;
}
