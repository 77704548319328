.employment-info__container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 2em;
    margin-left: 1em;
    margin-right: 1em;
}

.employment-info__company {
    font-family: 'Ubuntu', sans-serif;
    font-size: 1.5em;
}

.employment-info__title {
    font-family: 'Open Sans Condensed', sans-serif;
    font-size: 1.2em;
    color: #dde1e6;
}

.employment-info__description {
    max-width: 500px;
    margin-top: 10px;
    font-family: 'Open Sans Condensed', sans-serif;
    font-size: 0.75em;
    color: #a2a9b0;
}

.employment-info__time {
    max-width: 500px;
    margin-top: 10px;
    font-family: 'Ubuntu', sans-serif;
    font-size: 0.70em;
    color: #c1c7cd;
}