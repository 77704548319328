.about__container {
  padding-left: 2em;
  padding-right: 2em;
}

.about__background {
  background-image: linear-gradient(to bottom right, #061727, #004144);
}

.about__content-container {
  max-width: 1080px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  text-align: justify;
}

.about__content-image {
  width: 5em;
  border-radius: 50%;
}

.about__content-icon {
  font-size: 3em;
  color: #c1c7cd;
}
