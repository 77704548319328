.work__info-row {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.work__info-value {
    margin-left: 0.5em;
    font-size: 0.8em;
    line-height: 1.8em;
    color: #c1c7cd;
}